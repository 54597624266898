<template>

  <div class="flex mx-4" v-if="selectedEventData">
    <div class="w-1/4 px-2">
      <statistics-card-line
        hideChart
        class="mb-base"
        :icon="getIcon"
        :statistic="selectedEventData && selectedEventData.vote_stats.avg ? (Math.round(selectedEventData.vote_stats.avg * 10)/10) : 'n/a'"
        statisticTitle="Povprečna ocena"/>
    </div>
    <div class="w-1/4 px-2">
      <statistics-card-line
        hideChart
        class="mb-base"
        icon="ThumbsDownIcon"
        :statistic="selectedEventData && selectedEventData.vote_stats.min ? selectedEventData.vote_stats.min : 'n/a'"
        statisticTitle="Najnižja ocena"/>
    </div>
    <div class="w-1/4 px-2">
      <statistics-card-line
        hideChart
        class="mb-base"
        icon="ThumbsUpIcon"
        :statistic="selectedEventData && selectedEventData.vote_stats.max ? selectedEventData.vote_stats.max : 'n/a'"
        statisticTitle="Najvišja ocena"/>
    </div>
    <div class="w-1/4 px-2">
      <statistics-card-line
        hideChart
        class="mb-base"
        icon="HashIcon"
        :statistic="selectedEventData && selectedEventData.votes ? selectedEventData.votes.length : '0'"
        statisticTitle="Število vseh glasov"/>
    </div>
  </div>


</template>

<script>
  import Vue from 'vue';
  import StatisticsCardLine from '../../../components/statistics-cards/StatisticsCardLine.vue'


  export default {
    name: 'events-statistics',
    props: {
      eventId: Number
    },
    components: {
      StatisticsCardLine
    },

    data() {
      return {
        maxItems: 500,
        selectedEventData: null
      }
    },


    watch: {
      eventId: {
        handler(val, oldVal) {
          console.log("CHANGE!!!!");

          if (val) {
            this.getEventsDetails();
          }
        }
      }
    },

    computed: {
      getIcon() {
        const _this = this;
        if (_this.selectedEventData && _this.selectedEventData.vote_stats.avg) {
          if (_this.selectedEventData.vote_stats.avg < 2) {
            return 'FrownIcon';
          } else if (_this.selectedEventData.vote_stats.avg >= 2 && _this.selectedEventData.vote_stats.avg < 2.5) {
            return 'MehIcon';
          } else if (_this.selectedEventData.vote_stats.avg >= 2.5) {
            return 'SmileIcon';
          } else {
            return 'XCircleIcon';
          }
        } else {
          return 'XCircleIcon';
        }
      }
    },

    mounted() {
      const _this = this;

      _this.getEventsDetails();

    },

    destroyed() {
      console.log("destroyed");
      const _this = this;

    },

    beforeDestroy() {
      console.log("beforeDestroy");
      const _this = this;


    },

    methods: {

      getEventsDetails() {
        const _this = this;

        _this.$vs.loading();


        Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'event-evaluation/' + _this.eventId)
          .then((res) => {

            _this.selectedEventData =  res.data.data
            console.log("TOLE: ", res.data.data);

            _this.$vs.loading.close()
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },

      getUnitDataById(unitId) {
        const _this = this;

        if (localStorage.getItem('userInfo')) {
          let unit = _.filter(JSON.parse(localStorage.getItem('userInfo')).units, {id: unitId})[0];
          if (unit) {
            return unit.name + ' (' + unit.coveredMunicipalities + ')';
          } else
            return 'podatka ni bilo mogoče pridobiti';
        }

      },

      stopTheEvent: (event) => {
        event.stopPropagation();
        event.preventDefault();
      },


    }
  }
</script>

